@import '../../scss/base.module';

.modal-header {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;

  &__back-button {
    margin-left: 16px;
    padding-top: 3px;
  }

  &__close-button {
    padding-top: 4px;
  }

  &--show-back {
    padding-left: 0;

    .modal-header__title {
      @include font-bold;

      margin-right: 0;
      margin-left: 8px;
    }
  }

  &__title {
    @include font-bold;
    @include font-bold;

    margin: 0;
    margin-right: 16px;
    font-size: 21px;
    line-height: 1.5;
    font-weight: normal;
    flex: 1;
  }

  &__element {
    flex-basis: 100%;
  }
}
