@import '../../../scss/base.module';

.login-signup-modal-social {
  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: $spacing-m;
  }

  &__button {
    color: $medium !important; // The current Button component doesn't support custom styles
    background: rgba(92, 94, 109, 0.05) !important; // The current Button component doesn't support custom styles
    box-sizing: border-box !important;

    & > span { // The current Button component introduces a span by default
      display: flex;
      align-items: center;
      justify-content: center;

      & > * + * {
        @include font-bold;
        @include font-s;

        margin-left: $spacing-xs;
        padding-top: 2px;
      }
    }

    @include from-tablet {
      height: 60px;
    }
  }
}
