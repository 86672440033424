@import '../../../scss/base.module';

.forgotten-password-success-form {
  display: flex;
  justify-content: center;

  @include from-tablet {
    justify-content: flex-end;
  }

  * {
    @include font-bold;
    width: 50%;
  }
}
