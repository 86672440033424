@import '@spotahome/landings-common/src/scss/base';

.success-step {
  &__header,
  &__reach-email,
  &__priority,
  &__resolution-time {
    text-align: center;
    color: $dark;
  }

  &__header {
    @include font-bold;
    @include from-desktop {
      text-align: left;
      @include font-l;
    }
  }

  &__reach-email {
    margin-bottom: $spacing-l;
    @include from-desktop {
      text-align: left;
    }
  }

  &__image {
    width: 200px;
    margin: 0 auto;
    @include from-desktop {
      display: none;
    }
  }

  &__priority {
    margin-bottom: $spacing-l;
    @include from-desktop {
      text-align: left;
    }
  }

  &__sos-number {
    @include font-l;
    @include font-bold;
    color: $dark;
    text-align: center;
    @include from-desktop {
      text-align: left;
    }
  }
}
