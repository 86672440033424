@import '../../scss/base.module';

.form-phone-input {
  @include font-bold;
  @include font-m;

  background-color: var(--sah-input-background);
  border-radius: 4px;
  border: solid 1px $light;
  box-shadow: 0 2px 6px 0 $shadow;
  display: flex;
  width: 100%;
  flex: 1;
  color: $interaction-dark;
  align-items: center;
  word-break: break-all;
  padding-left: 12px;
  padding-top: 26px;
  padding-bottom: 10px;
  appearance: none;

  &:hover:not(:focus-within) {
    border-color: $interaction-dark;
  }

  &:focus-within {
    outline: none;
    border: solid 1.5px $interaction;
  }

  &__flag-button {
    margin-top: 30px;
    margin-bottom: 13px;

    &--with-separator {
      border-right: solid 1px $light !important;
    }
  }

  .form-phone-input__input--disabled {
    background-color: $light;
  }

  &--disabled {
    opacity: 0.3;
    cursor: not-allowed;
    background-color: $light;
    border-color: $light;

    &:hover:not(:focus) {
      border-color: $light;
    }
  }

  &--with-error {
    border: solid 1.5px $danger;
    color: $medium;
  }

  &__wrapper {
    position: relative;
    display: flex;
    width: 100%;
    flex: 1;
  }

  &__input {
    @include font-bold;
    @include font-m;

    color: $interaction-dark;
    width: 100%;
    padding-right: $spacing-m;
  }

  &__title {
    @include font-xs;
    @include font-regular;

    color: $medium;
    position: absolute;
    z-index: 1;
    top: 10px;
    left: 12px;

    &--with-error {
      color: $danger;
    }
  }
}

input::placeholder {
  font-style: normal;
  color: $medium;
}
