@import '../../scss/base.module';

.checkboxWithLabel {
  display: flex;
  align-items: flex-start;

  @include from-tablet {
    align-items: center;
  }

  &--top {
    @include from-tablet {
      align-items: flex-start;
    } 
  }
}

.text {
  @include font-s;

  padding-left: 12px;

  &:is(label) {
    cursor: pointer;
  }
}

.title {
  @include font-bold;
  color: $dark;
}

.count {
  margin-left: $spacing-xs;
}
