@import '../../../scss/base.module';

.login-signup-modal-separator {
  @include font-xs;
  @include font-bold;

  text-transform: uppercase;
  color: $medium;
  display: flex;
  align-items: center;
  margin: $spacing-m 0;

  @include from-tablet {
    margin: $spacing-s 0;
  }

  &:after, &:before {
    display: block;
    content: '';
    flex: 1;
    border-bottom: 1px solid $light;
  }

  &:before {
    margin-right: $spacing-xs;
  }

  &:after {
    margin-left: $spacing-xs;
  }
}
