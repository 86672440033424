@import '../../scss/base.module';

$time: 250ms;
$animation: ease-out;

div.formdate {
  background-color: white;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
}

div.formdate__calendar-wrapper {

  --border-color: #{$light};
  --arrow-base-size: #{$spacing-xxl};
  --month-label-color: #{$interaction-dark};
  --month-arrow-color: #{$interaction-dark};
  --month-select-arrow-color: #{$interaction-dark};

  border-color: var(--border-color);
  border-radius: $spacing-xs;
  background-color: white;
  max-height: 500px;
  transition: max-height $time $animation, opacity $time $animation;
  width: 100%;
  overflow: hidden;
  padding: 0;
  margin-top: $spacing-xs;

  &--absolute {
    left: 0;
    position: absolute;
    z-index: 9999;
  }

  &--hidden {
    margin: 0;
    max-height: 0;
    opacity: 0;
  }
  &--bordered {
    border-width: 1px;
    border-style: solid;
    border-radius: $spacing-xs;
  }

  abbr {
    color: $medium;
  }
}

.formdate__input {
  position: relative;

  &--selected {
    &::after {
      content: '';
      width: 100%;
      height: 5px;
      background-color: $interaction;
      border-radius: 0 0 $border-radius $border-radius;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}
