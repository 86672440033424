@import '../../scss/base.module';

$base-input-width: 180px;

.input {
  @include font-xs;

  padding: $spacing-xxs;
  display: inline-block;
  width: $base-input-width;
  border: 1px solid $light;
  border-radius: $border-radius;
  box-shadow: inset 0 2px 0 rgba($interaction-dark, 0.08);
  background-color: var(--sah-input-background);
  transition: all 0.1s ease-out;

  &:focus {
    outline: none;
    border: 1px solid $light;
  }

  &:disabled {
    background-color: $light;
  }
}

// Width
.full {
  width: 100%;
}

// Size
.big {
  @include font-xs;

  padding: $spacing-s;
}
