$min-z-index-background: -999;
$max-z-index-background: 0;

$min-z-index-main: 1;
$tooltip-z-index: 3;
$max-z-index-main: 1000;

$min-z-index-expanding: 1001;
$max-z-index-expanding: 2000;

$min-z-index-popup: 2001;
$max-z-index-popup: 3000;

$min-z-index-floating: 3001;
$max-z-index-floating: 4000;

$min-z-index-overlay: 4001;
$max-z-index-overlay: 5000;
