@import '../../../scss/base.module';

.login-signup-modal-title {
  &__title {
    @include font-bold;
    @include font-xl;

    line-height: 1.4; // override to save vertical space in mobile
    color: $interaction-dark;
    margin: 0;
    margin-bottom: $spacing-xs;
  }

  &__subtitle {
    @include font-s;

    line-height: 1.4; // override to save vertical space in mobile
    color: $interaction-dark;
    margin: 0 0 $spacing-s;

    @include from-tablet {
      line-height: 1.6;
    }
  }

  &__historic-metrics-wrapper {
    display: block;

    @include from-tablet {
      display: none;
    }
  }
}
