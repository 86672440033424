@import '../../../scss/base.module';

.login-signup-modal-toggle {
  display: flex;
  align-items: baseline;
  color: $interaction-dark;
  flex-wrap: wrap;

  @include from-tablet {
    margin-top: 0;
  }

  &--regular,
  &--regular * {
    @include font-s;
  }

  &--small,
  &--small * {
    @include font-xs;

    @include from-tablet {
      @include font-s;
    }
  }

  &__text {
    margin: 0;
    margin-right: $spacing-s;
  }

  &__toggle * {
    @include font-bold;

    color: $interaction;
    margin-right: $spacing-m;
  }
}
