@import '../../scss/base.module';

.count {
  @include font-bold;
  font-size: $font-xxs;
  border-radius: 100%;
  background-color: $secondary-pink-light;
  color: $interaction-light;
  width: $spacing-l;
  height: $spacing-l;
  text-align: center;
  align-content: center;
}

.loading::after {
  display: inline-block;
  animation: dots steps(1,end) 1s infinite;
  content: '';
}

@keyframes dots {
  0% { content: ''; }
  25% { content: '.'; }
  50% { content: '..'; }
  75% { content: '...'; }
  100% { content: ''; }
}
