$font-w-light: var(--sah-font-w-light);
$font-w-regular: var(--sah-font-w-regular);
$font-w-bold: var(--sah-font-w-bold);
$font-w-extra-bold: var(--sah-font-w-extra-bold);
$font-w-ultra-bold: var(--sah-font-w-ultra-bold);

:export {
  font-light: $font-w-light;
  font-regular: $font-w-regular;
  font-bold: $font-w-bold;
  font-extra-bold: $font-w-extra-bold;
  font-ultra-bold: $font-w-ultra-bold;
}
