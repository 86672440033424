@import '../../scss/base.module';

$time: 250ms;
$animation: ease-out;
$pickaday-title-height: 60px;

:global {
  .pika-single {
    padding: 0 $spacing-s;

    background: white;
    border-radius: $spacing-xs;
    display: block;
    overflow: hidden;
    position: relative;
    z-index: 9999;

    @include from-laptop {
      padding: 0 $spacing-l;
    }

    thead abbr {
      margin-bottom: $spacing-xs;
    }
  }

  .pika-title {
    position: relative;
    text-align: center;
    line-height: $pickaday-title-height !important;

    @include from-tablet {
      margin-bottom: $spacing-s;
      padding: 0;
    }

    select {
      @include font-m;

      cursor: pointer;
      position: absolute;
      z-index: 9998;
      margin: 0;
      left: 0;
      filter: alpha(opacity = 0);
      opacity: 0;
    }
  }

  .pika-label {
    @include font-m;
    @include font-bold;
    @include font-bold;

    color: var(--month-label-color);
    display: inline-block;
    position: relative;
    z-index: 9999;
    overflow: hidden;
    margin: 0;
    vertical-align: middle;
    text-transform: uppercase;

    &:first-child {
      padding-right: $spacing-s;
    }

    &:after {
      border-color: var(--month-select-arrow-color) transparent transparent transparent;
      border-style: solid;
      border-width: 5px 4px 0;
      content: '';
      display: inline-block;
      margin-left: 2px;
      vertical-align: middle;
    }
  }

  .pika-prev,
  .pika-next {
    display: block;
    cursor: pointer;
    position: relative;
    outline: none;
    border: 0;
    padding: 0;
    width: 20px;
    height: $pickaday-title-height;
    text-indent: 20px;
    white-space: nowrap;
    overflow: hidden;
    background-color: transparent;
    color: transparent;

    &:hover {
      opacity: 1;
    }

    &.is-disabled {
      cursor: default;
      opacity: 0.2;
    }

    &::before {
      content: '';
      display: block;
      border: 3px solid var(--month-arrow-color);
      width: 14px;
      height: 14px;
      position: absolute;
      top: 50%;
    }
  }

  .pika-prev,
  .is-rtl .pika-next {
    float: left;

    &::before {
      border-right-width: 0px;
      border-top-width: 0px;
      border-bottom-left-radius: 2px;
      transform: translate(50%, -50%) rotate(45deg);
      left: 0;
    }
  }

  .pika-next,
  .is-rtl .pika-prev {
    float: right;

    &::before {
      border-left-width: 0px;
      border-top-width: 0px;
      border-bottom-right-radius: 2px;
      transform: translate(-50%, -50%) rotate(-45deg);
      right: 0;
    }
  }

  .pika-select {
    display: inline-block;
  }

  .pika-table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    border: 0;

    thead {
      tr {
        th {
          padding: 0 0 ($spacing-xs);
        }
      }
    }

    th,
    td {
      @include font-s;

      color: $medium;
      padding: 0;
      width: 14.28571%;
      display: inline-flex;
      justify-content: center;
    }

    th {
      text-align: center;
      text-transform: uppercase;
    }

    abbr {
      @include font-xs;

      border-bottom: none;
      cursor: help;
    }
  }

  .pika-button {
    @include font-s;
    line-height: 1;

    cursor: pointer;
    display: block;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    outline: none;
    border: 1px solid transparent;
    margin: 0;
    text-align: center;
    background: transparent;
    color: $interaction;
    font-weight: bold;
    padding: 5px;
    height: $spacing-xl;
    width: $spacing-xl;
    border-radius: 50%;

    &:hover {
      color: white;
      background-color: $interaction;
      opacity: 0.5;
    }

  }
  .is-today .pika-day {
    @include font-bold;
    border-color: $interaction;
  }

  .is-selected .pika-day {
    @include font-bold;
    color: white;
    color: white;
    background-color: $interaction;
  }

  .is-disabled .pika-day {
    color: $light;
    font-weight: normal;
    pointer-events: none;
    cursor: default;
  }

  .pika-day {
    @include font-bold;
  }

  .pika-lendar {
    padding-bottom: $spacing-s;
  }
}
