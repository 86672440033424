@import '../../scss/base.module';

$text-top-align: 30px;

.form-select {
  @include font-light;
  @include font-m;

  background-color: var(--sah-input-background);
  border-radius: 4px;
  border: solid 1px $light;
  box-shadow: 0 2px 6px 0 $shadow;
  display: flex;
  width: 100%;
  color: $medium;
  align-items: center;
  word-break: break-all;
  padding-left: 12px;
  padding-top: $text-top-align;
  padding-bottom: 13px;
  padding-right: 37px;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  text-overflow: ellipsis;

  &--option-selected {
    @include font-bold;

    color: $interaction-dark;
  }

  &--hidden-title {
    padding-top: 13px;
  }

  &:hover:not(:focus) {
    cursor: pointer;
    border-color: $interaction-dark;
  }

  &:focus {
    outline: none;
    border: solid 1.5px $interaction;
  }

  &--disabled {
    opacity: 0.3;
    cursor: not-allowed;
    background-color: $light;
    border-color: $light;

    &:hover:not(:focus) {
      border-color: $light;
    }
  }

  &--with-error {
    border: solid 1.5px $danger;
    color: $medium;
  }

  &__wrapper {
    position: relative;
    display: flex;
    width: 100%;
    flex: 1;
  }

  &__title {
    @include font-xs;
    @include font-bold;

    color: $medium;
    position: absolute;
    top: 10px;
    left: 12px;

    &--with-error {
      color: $danger;
    }
  }

  &__chevron {
    &:before {
      position: absolute;
      color: $interaction-dark;
      border-style: solid;
      border-width: 0.15em 0.15em 0 0;
      content: '';
      display: inline-block;
      height: 0.45em;
      width: 0.45em;
      right: 16px;
      margin-top: $text-top-align;
      transform: rotate(135deg);
      box-sizing: content-box;
    }

    &--hidden-title:before {
      margin-top: 17px;
    }

    &--disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }
}
