$golden-ratio: 1.61803398875;
$line-height: 1rem * $golden-ratio;

$sans-serif: var(--sah-font-sans-serif, 'sans-serif');
$serif: var(--sah-font-serif, 'serif');
$monospaced: var(--sah-font-monospace, 'monospace');

$font-xxxs: var(--sah-font-xxxs);
$font-xxs: var(--sah-font-xxs);
$font-xs: var(--sah-font-xs);
$font-s: var(--sah-font-s);
$font-m: var(--sah-font-m);
$font-l: var(--sah-font-l);
$font-xl: var(--sah-font-xl);
$font-xxl: var(--sah-font-xxl);
$font-xxxl: var(--sah-font-xxxl);
$font-xxxxl: var(--sah-font-xxxxl);
$font-xxxxxl: var(--sah-font-xxxxxl);
$font-xxxxxxl: var(--sah-font-xxxxxxl);
$font-xxxxxxxl: var(--sah-font-xxxxxxxl);
$font-xxxxxxxxl: var(--sah-font-xxxxxxxxl);

$font-light: var(--sah-font-light);
$font-regular: var(--sah-font-regular);
$font-bold: var(--sah-font-bold);
$font-extra-bold:var(--sah-font-extra-bold);
$font-ultra-bold: var(--sah-font-ultra-bold);
$font-title: var(--sah-font-title);

:export {
  font-light: $font-light;
  font-regular: $font-regular;
  font-bold: $font-bold;
  font-extra-bold: $font-extra-bold;
  font-ultra-bold: $font-ultra-bold;
  font-title: $font-title;
}
