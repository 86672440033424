@import '../../../scss/base.module';

.forgotten-password-form {
  &__field {
    margin-bottom: $spacing-xs;
    box-sizing: border-box;

    @include from-tablet {
      margin-bottom: $spacing-m;
    }
  }

  &__go-back {
    margin-bottom: $spacing-m;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column-reverse;
    margin-top: $spacing-xs;

    @include from-tablet {
      flex-direction: row;
      align-items: center;
      margin-top: 0;
    }
  }

  &__footer-tip {
    @include font-bold;
    @include font-s;

    color: $interaction-dark;
    margin-right: $spacing-m;
  }

  &__submit,
  &__submit * {
    @include font-bold;

    width: 100%;
    margin-bottom: 0;

    @include from-tablet {
      width: auto;
    }
  }

  &__error {
    @include font-s;
    @include font-bold;

    color: $danger;
    margin-top: $spacing-m;

    @include from-laptop {
      margin-top: $spacing-xs;
    }
  }
}
