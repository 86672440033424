.logo {
  display: block;

  &--small {
    max-width: 91px;
    max-height: 42px;
  }

  &--normal {
    max-width: 130px;
    max-height: 60px;
  }

  &--large {
    max-width: 190px;
    max-height: 88px;
  }
}
