@import '../../scss/base.module';

.icon-button {
  text-decoration: none;
  fill: #606060;
  cursor: pointer;
  border: none;
  background: none;
  padding: 0;
  line-height: 0;

  &:focus {
    outline: none;
    fill: $interaction-dark;
  }
}
