.wrapper {
  align-content: center;
  display: flex;
  justify-content: center;
  position: relative;

}
.checkbox {
  opacity: 0;
  position: absolute;
  -webkit-appearance: none;
  cursor: pointer;
}

.checkbox-size-normal {
  width: 24px;
  height: 24px;
}

.checkbox-size-small {
  width: 18px;
  height: 18px;
}
