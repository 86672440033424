@import '../../scss/base.module';

.login-signup-modal {
  display: flex;

  &__historic-metrics-wrapper {
    display: none;

    @include from-tablet {
      display: block;
      width: 45%;
      height: 100%;
    }
  }

  &__image-container {
    display: none;

    @include from-tablet {
      display: block;
      width: 45%;
      background-image: url('http://static.spotahome.com/login-signup-modal/login-signup-modal.jpg');
      background-size: cover;
      background-position-x: 15%;
    }

    @include from-laptop {
      background-position-x: unset;
    }
  }

  &__content-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: content-box;
    align-items: center;
    overflow-y: auto;

    @include from-tablet {
      width: 55%;
    }

    @include from-wide {
      max-width: 780px;
      margin: 0 auto;
    }
  }

  &__content-header {
    width: 100%;
    box-sizing: border-box;
    padding: $spacing-m;

    & > button {
      margin-left: 0 !important; // TODO ModalHeader adds a hardcoded 16px margin left
    }

    @include from-laptop {
      max-width: 85%;
      padding-top: $spacing-l;
    }
  }

  &__content-body {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0 $spacing-m;
    width: 100%;
    box-sizing: border-box;

    @include from-tablet {
      justify-content: center;
    }

    @include from-laptop {
      max-width: 85%;
    }
  }

  &__methods {
    display: flex;
    flex-direction: column;

    &--reversed {
      flex-direction: column-reverse;
    }
  }

  &__action-container {
    margin: 0 $spacing-xs $spacing-s;

    @include from-tablet {
      margin: $spacing-s 0 $spacing-xs;
    }
  }

  &__error {
    @include font-s;

    color: $danger;

    @include from-desktop {
      text-align: right;
    }
  }
}
