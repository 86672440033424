@import '../../../scss/base.module';

.login-signup-modal-terms-and-conditions {
  @include font-s;

  line-height: 1.5;
  color: $interaction-dark;
  margin-bottom: $spacing-xs;
  width: 100%;

  a {
    color: $interaction;
    text-decoration: none;
  }
}
