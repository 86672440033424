//Colors from new palette (https://www.figma.com/file/OehYeVdgon8iPQy6n8z6jv/New-library?node-id=1%3A50)
// PRIMARY
$primary: var(--sah-primary);
$interaction: var(--sah-interaction);
$secondary-pink: var(--sah-secondary-pink);
$secondary-blue: var(--sah-secondary-blue);
$interaction-dark: var(--sah-interaction-dark);
$tertiary: var(--sah-tertiary);
// SECONDARY
$danger: var(--sah-danger);
$success: var(--sah-success);
$warning: var(--sah-warning);
$white: var(--sah-white);
// TINTS
$primary-light: var(--sah-primary-light);
$interaction-light: var(--sah-interaction-light);
$secondary-blue-light: var(--sah-secondary-blue-light);
$secondary-pink-light: var(--sah-secondary-pink-light);
// GREYS
$medium: var(--sah-medium);
$light: var(--sah-light);
$ultra-light: var(--sah-ultra-light);
$medium-light: var(--sah-medium-light);
// OVERLAYS
$overlay-light-black: var(--sah-overlay-light-black);
$overlay-black: var(--sah-overlay-black);
$overlay-white: var(--sah-overlay-white);
$overlay-pink: var(--sah-overlay-pink);

//DEPRECATED:
$primary-light-blue: var(--sah-primary-light-blue);
$secondary: var(--sah-secondary);
$ultra-dark: var(--sah-ultra-dark);
$dark: var(--sah-dark);
$info: var(--sah-info);
$primary-dark: var(--sah-primary-dark);
$translucid: var(--sah-translucid);
$shadow: var(--sah-shadow);

:export {
  primary: $primary;
  interaction: $interaction;
  secondary-pink: $secondary-pink;
  secondary-blue: $secondary-blue;
  interaction-dark: $interaction-dark;
  tertiary: $tertiary;
  danger: $danger;
  success: $success;
  warning: $warning;
  white: $white;
  primary-light: $primary-light;
  interaction-light: $interaction-light;
  secondary-blue-light: $secondary-blue-light;
  secondary-pink-light: $secondary-pink-light;
  medium: $medium;
  light: $light;
  medium-light: $medium-light;
  ultra-light: $ultra-light;
  overlay-light-black: $overlay-light-black;
  overlay-black: $overlay-black;
  overlay-white: $overlay-white;
  overlay-pink: $overlay-pink;

  primary-light-blue: $primary-light-blue;
  secondary: $secondary;
  ultra-dark: $ultra-dark;
  dark: $dark;
  info: $info;
  primary-dark: $primary-dark;
  translucid: $translucid;
  shadow: $shadow;
}
