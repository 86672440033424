$line-height-xxxs: var(--sah-line-height-xxxs);
$line-height-xxs: var(--sah-line-height-xxs);
$line-height-xs: var(--sah-line-height-xs);
$line-height-s: var(--sah-line-height-s);
$line-height-m: var(--sah-line-height-m);
$line-height-l: var(--sah-line-height-l);
$line-height-xl: var(--sah-line-height-xl);
$line-height-xxl: var(--sah-line-height-xxl);
$line-height-xxxl: var(--sah-line-height-xxxl);
$line-height-xxxxl: var(--sah-line-height-xxxxl);
$line-height-xxxxxl: var(--sah-line-height-xxxxxl);
$line-height-xxxxxxl: var(--sah-line-height-xxxxxxl);
$line-height-xxxxxxxl: var(--sah-line-height-xxxxxxxl);
$line-height-xxxxxxxxl: var(--sah-line-height-xxxxxxxxl);

:export {
  line-height-xxxs: $line-height-xxxs;
  line-height-xxs: $line-height-xxs;
  line-height-xs: $line-height-xs;
  line-height-s: $line-height-s;
  line-height-m: $line-height-m;
  line-height-l: $line-height-l;
  line-height-xl: $line-height-xl;
  line-height-xxl: $line-height-xxl;
  line-height-xxxl: $line-height-xxxxl;
  line-height-xxxxl: $line-height-xxxxl;
  line-height-xxxxxl: $line-height-xxxxxl;
  line-height-xxxxxxl: $line-height-xxxxxxl;
  line-height-xxxxxxxl: $line-height-xxxxxxxl;
  line-height-xxxxxxxxl: $line-height-xxxxxxxxl;
}
