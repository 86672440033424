@import '../../../scss/base.module';

.contact-form {
  &__field {
    margin-bottom: $spacing-xs;

    @include from-tablet {
      margin-bottom: $spacing-s;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    box-sizing: border-box;

    @include from-tablet {
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
    }
  }

  &__recaptcha {
    margin-bottom: $spacing-m;

    @include from-tablet {
      margin-bottom: 0;
    }
  }

  &__submit {
    @include from-tablet {
      margin-top: $spacing-m;
      margin-bottom: 0;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.07);
      order: 3;
    }
  }

  &__submit,
  &__submit * {
    @include font-bold;

    width: 100%;

    @include from-tablet {
      width: auto;
      margin-bottom: 0;
    }
  }

  &__error {
    @include font-s;
    @include font-bold;

    color: $danger;
    margin-top: $spacing-m;

    @include from-laptop {
      margin-top: $spacing-xs;
    }
  }
}