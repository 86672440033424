@import './../variables/fonts.module';
@import './../variables/font-weights.module';
@import './../variables/line-heights.module';

// FONT WEIGHT
@mixin font-light {
  font-family: $font-light;
  font-weight: $font-w-light;
}
@mixin font-regular {
  font-family: $font-regular;
  font-weight: $font-w-regular;
}
@mixin font-bold {
  font-family: $font-bold;
  font-weight: $font-w-bold;
}
@mixin font-extra-bold {
  font-family: $font-extra-bold;
  font-weight: $font-w-extra-bold;
}
@mixin font-ultra-bold {
  font-family: $font-ultra-bold;
  font-weight: $font-w-ultra-bold;
}
@mixin font-title {
  font-family: $font-title;
  font-weight: $font-w-regular;
}

// FONT SIZE
@mixin font-xxxs {
  font-size: $font-xxxs;
  line-height: $line-height-xxxs;
}
@mixin font-xxs {
  font-size: $font-xxs;
  line-height: $line-height-xxs;
}
@mixin font-xs {
  font-size: $font-xs;
  line-height: $line-height-xs;
}
@mixin font-s {
  font-size: $font-s;
  line-height: $line-height-s;
}
@mixin font-m {
  font-size: $font-m;
  line-height: $line-height-m;
}
@mixin font-l {
  font-size: $font-l;
  line-height: $line-height-l;
}
@mixin font-xl {
  font-size: $font-xl;
  line-height: $line-height-xl;
}
@mixin font-xxl {
  font-size: $font-xxl;
  line-height: $line-height-xxl;
}
@mixin font-xxxl {
  font-size: $font-xxxl;
  line-height: $line-height-xxxl;
}

@mixin font-xxxxl {
  font-size: $font-xxxxl;
  line-height: $line-height-xxxxl;
}

@mixin font-xxxxxl {
  font-size: $font-xxxxxl;
  line-height: $line-height-xxxxxl;
}

@mixin font-xxxxxxl {
  font-size: $font-xxxxxxl;
  line-height: $line-height-xxxxxxl
}

@mixin font-xxxxxxxl {
  font-size: $font-xxxxxxxl;
  line-height: $line-height-xxxxxxxl
}

@mixin font-xxxxxxxxl {
  font-size: $font-xxxxxxxxl;
  line-height: $line-height-xxxxxxxxl
}
