@import '../../scss/base.module';

:global {
  .ReactModal__Body--open {
    overflow: hidden;
  }
}

.modal {
  position: relative;
  margin: $spacing-l;
  min-height: 234px;
  max-height: calc(100% - 28px);
  background-color: white;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  outline: none;
  z-index: $max-z-index-popup;
}

.modal-small {
  width: 360px;
}

.modal-normal {
  width: 430px;
}

.modal-medium {
  width: 700px;
}

.modal-big {
  width: 860px;
}

.modal-fullscreen {
  width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0;
}

.modal--rounded {
  border-radius: 10px;
}

.modal--corner {
  $corner-margin: 48px;

  &-top-right {
    @include from-tablet {
      position: fixed;
      bottom: auto;
      left: auto;
      top: $corner-margin;
      right: $corner-margin;
      margin: 0;
    }
  }

  &-top-right-full {
    @extend .modal--corner-top-right;
    top: 0;
    right: 0;
    border-radius: 0;
  }

  &-top-left {
    @include from-tablet {
      position: fixed;
      bottom: auto;
      right: auto;
      top: $corner-margin;
      left: $corner-margin;
      margin: 0;
    }
  }

  &-top-left-full {
    @extend .modal--corner-top-left;
    top: 0;
    left: 0;
    border-radius: 0;
  }

  &-bottom-right {
    @include from-tablet {
      position: fixed;
      top: auto;
      left: auto;
      bottom: $corner-margin;
      right: $corner-margin;
      margin: 0;
    }
  }

  &-bottom-right-full {
    @extend .modal--corner-bottom-right;
    bottom: 0;
    right: 0;
    border-radius: 0;
  }

  &-bottom-left {
    @include from-tablet {
      position: fixed;
      top: auto;
      right: auto;
      bottom: $corner-margin;
      left: $corner-margin;
      margin: 0;
    }
  }

  &-bottom-left-full {
    @extend .modal--corner-bottom-left;
    bottom: 0;
    left: 0;
    border-radius: 0;
  }
}

.overlay {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 42, 77, 0.8);
  z-index: $max-z-index-overlay;
}
