@import '../../scss/base.module';

$padding-side: 12px;
$padding-top: 30px;

.form-input {
  @include font-bold;
  @include font-m;

  background-color: var(--sah-input-background);
  border-radius: 4px;
  border: solid 1.5px $light;
  box-shadow: 0 2px 6px 0 $shadow;
  display: flex;
  width: 100%;
  flex: 1;
  color: $interaction-dark;
  align-items: center;
  word-break: break-all;
  padding-top: $padding-top;
  padding-left: var(--input-padding-left);
  padding-right: var(--input-padding-right);
  padding-bottom: 13px;
  appearance: none;

  &:hover:not(:focus) {
    border-color: $interaction-dark;
  }

  &:focus {
    outline: none;
    border: solid 1.5px $interaction;
  }

  &--read-only {
    cursor: default;
  }

  &--bold {
    @include font-bold;
  }

  &--disabled {
    opacity: 0.3;
    cursor: not-allowed;
    background-color: $light;
    border-color: $light;

    &:hover:not(:focus) {
      border-color: $light;
    }
  }

  &--with-error {
    border: solid 1.5px $danger;
    color: $medium;
  }

  &__wrapper {
    --initial-padding: #{$padding-side};
    --input-padding-left: var(--initial-padding);
    --input-padding-right: var(--initial-padding);

    position: relative;
    display: flex;
    width: 100%;
    flex: 1;
  }

  &:focus + .form-input__title--textarea {
    margin-top: 1px;
    padding-top: 8px;
  }

  &__title {
    @include font-xs;
    @include font-bold;

    color: $medium;
    position: absolute;
    top: 10px;
    left: 12px;

    &--with-error {
      color: $danger;
    }

    &--with-error.form-input__title--textarea {
      margin-top: 1px;
      padding-top: 8px;
    }

    &--textarea {
      top: 1px;
      width: calc(100% - 12px - 20px); // extra space to avoid overlapping the scroll bar
      margin-bottom: 1px;
      padding-top: 9px;
      box-sizing: border-box;
    }
  }

  &__prefix,
  &__suffix {
    @include font-bold;
    @include font-m;

    position: absolute;
    top: $padding-top + 1;
  }

  &__prefix {
    left: $padding-side;
  }

  &__suffix {
    right: $padding-side;
  }
}

input::placeholder,
textarea::placeholder {
  @include font-light;

  font-style: normal;
  color: $medium;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
