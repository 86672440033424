@import '../../../scss/base.module';

.login-form {
  &__field {
    margin-bottom: $spacing-xs;

    @include from-tablet {
      margin-bottom: $spacing-s;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    box-sizing: border-box;

    @include from-tablet {
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
    }
  }

  &__submit {
    @include from-tablet {
      margin-top: 0;
      margin-bottom: 0;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.07);
      order: 3;
    }
  }

  &__submit,
  &__submit * {
    @include font-bold;

    width: 100%;

    @include from-tablet {
      width: auto;
      margin-bottom: 0;
    }
  }

  &__bottom-buttons {
    @include font-s;
    
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: $spacing-m;
    flex-flow: column;
    gap: $spacing-xs;

    @include from-tablet {
      margin-bottom: $spacing-s;
      height: $spacing-m;
      flex-flow: row;
      align-items: center;
      margin-bottom: $spacing-s;
    }
  }

  &__terms-and-conditions {
    @include from-tablet {
      order: 1;
      flex-grow: 1;
    }
  }

  &__toggle {
    @include from-tablet {
      order: 2;
    }
  }

  &__error {
    @include font-s;
    @include font-bold;

    color: $danger;
    margin-top: $spacing-m;

    @include from-laptop {
      margin-top: $spacing-xs;
    }
  }
}
