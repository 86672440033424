@import '../../scss/base.module';

.loading-icon {
  display: inline-block;
  width: 96px;
  height: 96px;
  position: relative;

  &.loading-icon--small {
    height: 8px;
    width: $spacing-l;
  }
}

.loading-icon:before,
.loading-icon:after {
  content: '';
  display: block;
  position: absolute;
  border-radius: $spacing-l;
  background: $dark;
  height: $spacing-l;
  width: $spacing-l;
  opacity: 0.4;
  animation: loading-bounce 2s infinite ease-in;
  top: $spacing-xl;
  left: $spacing-xl;
}

.loading-icon--small.loading-icon:before,
.loading-icon--small.loading-icon:after {
  height: $spacing-m;
  width: $spacing-m;
  top: calc(50% - 8px);
  left: calc(50% - 8px);
}

.loading-icon:after {
  animation-delay: 1s;
}

@keyframes loading-bounce {
  0% {
    transform: scale(1.2);
    opacity: 0;
  }

  15% {
    transform: scale(0.8);
    opacity: 0.4;
  }

  100% {
    transform: scale(4);
    opacity: 0;
  }
}
